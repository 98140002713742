import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Container = ({ noAnimation, children, slug }) => (
  <>
    {noAnimation ? (
      <div>{children}</div>
    ) : (
      <Link to={`/projects/${slug}`}>{children}</Link>
    )}
  </>
);

const CardWorkLong = ({ data, noAnimation }) => {
  const {
    background,
    contentful_id,
    country,
    cover,
    id,
    short: { short },
    service,
    slug,
    stack,
    title,
  } = data;
  return (
    <div key={id} className='col-12'>
      <div
        className={`work-card work-card-long ${
          noAnimation ? 'no-animation' : ''
        }`}
        style={{ background: background }}
      >
        <Container noAnimation={noAnimation} slug={slug}>
          <div className='d-flex justify-content-between flex-column flex-md-row'>
            <div className='work-content order-2 order-md-1'>
              <div className='work-title'>{title}</div>
              <div className='work-desc'>{short}</div>
              <div className='work-stack'>
                <strong>Services</strong>
                <br />
                {service.map(({ title }) => title).join(', ')}
              </div>
              <div className='work-stack'>
                <strong>Tech/Tools</strong>
                <br />
                {stack}
              </div>
              <div className='work-country'>
                <img
                  src={'/images/icons/location.svg'}
                  height={16}
                  width={16}
                  alt='Location icon'
                  loading='lazy'
                />
                {country}
              </div>
            </div>
            <div className='work-cover order-1 order-md-2'>
              {cover && (
                <GatsbyImage image={cover.gatsbyImageData} alt={cover.title} />
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CardWorkLong;
