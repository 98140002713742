import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import CardWorkLong from '../components/CardWorkLong';
import Layout from '../components/Layout';

const Projects = (props) => {
  const works = props.data.works.edges;
  const perPage = 10;
  const [list, setList] = React.useState([...works.slice(0, perPage)]);
  const [loadMore, setLoadMore] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(works.length > perPage);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < works.length;
      const nextResults = isMore
        ? works.slice(currentLength, currentLength + perPage)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  React.useEffect(() => {
    const isMore = list.length < works.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Layout bodyClass='page-works'>
      <div className='strip strip-blog strip-blog-page'>
        <div className='container'>
          <span className='section-title mb-3'>Projects</span>
          <div className='row'>
            {list.map(({ node }, index) => (
              <CardWorkLong key={node.id} data={node} />
            ))}
          </div>
          {hasMore && (
            <div className='row'>
              <div className='col-auto'>
                <button
                  className='button button-secondary btn-as-link'
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    works: allContentfulWorks(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          country
          title
          background
          slug
          stack
          short {
            short
          }
          desc {
            raw
          }
          cover {
            title
            gatsbyImageData(
              width: 600
              height: 450
              placeholder: BLURRED
              formats: [AUTO, PNG]
            )
          }
          service {
            title
          }
        }
      }
    }
  }
`;

export const Head = () => <SEO title='Projects' />;

export default Projects;
